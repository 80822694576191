import { useCreateProductData } from "~/composables/middlewareDataHolders"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const createProductData = useCreateProductData()
    const { get } = useOFetchCustom(`products/create`)
    const notify = useNotification()

    try {
        await new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    createProductData.value = response.data
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    } catch (error: any) {
        notify("negative", error._data.message)
        return navigateTo("/store")
    }
})
